(function() {
    BuyTickets = (function() {
      function BuyTickets(div) {
        this.divTicket = $(div);

        this.divTicket.on("click", (function(_this) {
          return function() {
            num = _this.divTicket.data("num");
            div = document.createElement("div");
            div.className = "col-md-3 buy d-flex select-ticket justify-content-center";
            div.id = "buy-" + num;

            divText = document.createElement("div");
            divText.innerHTML = num;
            divText.className= "col";

            aRemove = document.createElement("div");
            aRemove.id = "remove-" + num;
            aRemove.className= "col";
            icon = $('<i class="fa-solid fa-xmark"></i>');

            icon.appendTo(aRemove);

            div.appendChild(divText);
            div.appendChild(aRemove);

            document.getElementById("select-tickets").appendChild(div);

            new RemoveDivs(num);
            new RandomGift(num);
          };
        })(this));
      }

      return BuyTickets;

    })();

    RemoveDivs = (function() {
        function RemoveDivs(item) {
            this.num = item;
            this.itemRemove = $("#remove-" + item);

            this.itemRemove.on("click", (function(_this) {
                return function() {
                  $("#buy-" + _this.num).remove();
                  $(".color").each(function(i, obj) {
                    if( $(obj).data("parent") == _this.num){
                      $(obj).remove();
                    }
                  });
                }
            })(this));
        }

        return RemoveDivs;
    })();

    RandomGift = (function() {
        function RandomGift(num) {
            this.numSelect = num;
            this.container = $("#gift-tickets");
            this.arrayNums = $("#nums").val()
            this.product = $("#gift-tickets").data("product");
            this.buy = $(".buy").length
            $.ajax({
                url: this.container.data("url") + "?nums_exists=" + this.arrayNums + "&id=" + this.product +"&buy=" + this.buy +"&num_select=" + this.numSelect,
                method: "get",
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function (response) {
                    $("#gift-tickets").append(response.component);
                    var nums = ""
                    $('.color').each(function(i, obj) {
                        nums +=  obj.innerHTML + ",";
                    });
                    var inputNums = document.getElementById("nums");
                    inputNums.innerHTML = nums;
                    inputNums.value = nums;
                }
            });
        }

        return RandomGift;
    })();

    SendBuy = (function() {
        function SendBuy() {
            var tickets_num = []
            $('.buy').each(function(i, obj) {
                tickets_num.push(obj.firstElementChild.innerHTML);
            });
            var inputGifts = $("#nums").val();
            var gifts = inputGifts.split(",");
            tickets_num = tickets_num.concat(gifts);
            var urlBuy = $("#buy-tickets").data("url")
            var params = {}
            params["tickets_num"] = tickets_num;
            params["product_id"] = $("#gift-tickets").data("product");
            params["customer_name"] = document.getElementById("customer").value;
            params["phone_contact"] = document.getElementById("phone").value;
            $.ajax({
                url: urlBuy,
                data: JSON.stringify(params),
                method: "POST",
                dataType: "json",
                contentType: "application/json",
                success: function (response) {
                    swal("Tus boletos han sido apartados, comunicate con nosotros mediante WhatsApp.")
                    .then((value) => {
                        window.open(response.url_wp, "_blank");
                        window.location.href = response.next_url;
                    });

                }
            });
        }

        return SendBuy;
    })();

    $(document).on("turbolinks:load", function() {
        $(".ticket").each(function() {
            return new BuyTickets(this);
        });
        $("#buy-tickets").on("click", (function(_this) {
            new SendBuy();
        }));
        $(".paginate").paginga({
            page: 1,
            item:"> div",
            itemsPerPage: 100
        });
        $('#search').on("click",function(){
            $('.ticket').hide();
            var txt = $('#search-criteria').val();
            $('.ticket:contains("'+txt+'")').show();
        });
    });

}).call(this);
